import React, { useState } from 'react';
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import RichTextEditor from './modules/RichTextEditor';

const QuestionsPanel = ({ questions, setQuestions, selectedBackground, changeColors, CloudColors }) => {
    const [editingQuestions, setEditingQuestions] = useState({});

    // const visibleQuestions = questions.filter((q) => !q.hidden);

    const handleEditQuestion = (index) => {
        setEditingQuestions({ ...editingQuestions, [index]: true });
    };

    const saveEditedQuestion = (index, newText) => {
        const updatedQuestions = [...questions];
        updatedQuestions[index].text = newText;
        setQuestions(updatedQuestions);
        setEditingQuestions({ ...editingQuestions, [index]: false });
    };

    const cancelEdit = (index) => {
        setEditingQuestions({ ...editingQuestions, [index]: false });
    };

    const handleDeleteQuestion = (index) => {
        const updatedQuestions = questions.filter((_, i) => i !== index);
        setQuestions(updatedQuestions);
    };

    const renderQuestionItem = (question, index, groupOffset) => (
        <li key={index + groupOffset} style={{ '--cloud-color': CloudColors[selectedBackground] }}>
            {editingQuestions[index + groupOffset] ? (
                <>
                    <RichTextEditor
                        initialValue={question.text}
                        onSave={(newText) => saveEditedQuestion(index + groupOffset, newText)}
                        onCancel={() => cancelEdit(index + groupOffset)}
                    />
                </>
            ) : (
                <>
                    <MathJax>
                        <span dangerouslySetInnerHTML={{ __html: question.text }} />
                    </MathJax>
                    <button
                        onClick={() => handleEditQuestion(index + groupOffset)}
                        style={{
                            marginLeft: '10px',
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '1.2rem',
                        }}
                        title="Редактировать вопрос"
                    >
                        ✏️
                    </button>
                    <button
                        onClick={() => handleDeleteQuestion(index + groupOffset)}
                        style={{
                            marginLeft: '10px',
                            background: 'transparent',
                            border: 'none',
                            cursor: 'pointer',
                            fontSize: '1.2rem',
                        }}
                        title="Удалить вопрос"
                    >
                        🗑️
                    </button>
                </>
            )}
        </li>
    );

    return (
        <MathJaxContext>
            <div className="questions-panel">
                <h3 style={{ color: changeColors[selectedBackground], borderColor: changeColors[selectedBackground] }}>
                    Вопросы по горизонтали:
                </h3>
                <ul>
                    {questions
                        .filter((question) => question.direction === 'horizontal')
                        .map((question, index) => renderQuestionItem(question, index, 0))}
                </ul>

                <h3 style={{ color: changeColors[selectedBackground], borderColor: changeColors[selectedBackground] }}>
                    Вопросы по вертикали:
                </h3>
                <ul>
                    {questions
                        .filter((question) => question.direction === 'vertical')
                        .map((question, index) =>
                            renderQuestionItem(question, index, questions.filter((q) => q.direction === 'horizontal').length)
                        )}
                </ul>
            </div>
        </MathJaxContext>
    );
};

export default QuestionsPanel;
