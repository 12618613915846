import './App.css';

const Grid = ({ questions, onCellChange, onDropQuestion }) => {
    const getGridDimensions = () => {
        let maxRow = 1;
        let maxCol = 1;

        questions.forEach((question) => {
            const [startRow, startCol] = question.start;

            if (question.direction === 'horizontal') {
                maxCol = Math.max(maxCol, startCol + question.length);
                maxRow = Math.max(maxRow, startRow + 1); // +1 для учета строки
            } else if (question.direction === 'vertical') {
                maxRow = Math.max(maxRow, startRow + question.length);
                maxCol = Math.max(maxCol, startCol + 1); // +1 для учета колонки
            }
        });

        return { rows: maxRow, columns: maxCol };
    };

    const { rows, columns } = getGridDimensions();
    const containerSize = 500;
    const cellSize = Math.min(containerSize / rows, containerSize / columns);

    const handleDragStart = (e, questionIndex) => {
        e.dataTransfer.setData('questionIndex', questionIndex);
    };

    const handleDrop = (e, rowIndex, cellIndex) => {
        e.preventDefault();
        const questionIndex = e.dataTransfer.getData('questionIndex');
        onDropQuestion(questionIndex, rowIndex, cellIndex);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const renderCell = (rowIndex, cellIndex) => {
        for (const [index, question] of questions.entries()) {
            const [startRow, startCol] = question.start;
            const answer = question.answer;

            // Проверяем, если это ячейка для отображения номера вопроса (на -1 позиции)
            const isNumberCell =
                (question.direction === 'horizontal' && rowIndex === startRow && cellIndex === startCol - 1) ||
                (question.direction === 'vertical' && cellIndex === startCol && rowIndex === startRow - 1);

            // Отображаем ячейку с номером перед первой ячейкой ответа
            if (isNumberCell) {
                return (
                    <div
                        className="crossword-num"
                        style={{ position: 'relative', width: `${cellSize}px`, height: `${cellSize}px` }}
                        key={`${rowIndex}-${cellIndex}`}
                    >
                        {index + 1}
                    </div>
                );
            }

            // Горизонтальный вопрос
            if (question.direction === 'horizontal' && rowIndex === startRow && cellIndex >= startCol && cellIndex < startCol + question.length) {
                const answerIndex = cellIndex - startCol;
                return (
                    <input
                        className="grid-cell answer-cell"
                        style={{ width: `${cellSize}px`, height: `${cellSize}px` }}
                        key={`${rowIndex}-${cellIndex}`}
                        value={answer[answerIndex] || ''}
                        maxLength={1}
                        draggable
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, rowIndex, cellIndex)}
                        onChange={(e) => onCellChange(rowIndex, cellIndex, e.target.value)}
                    />
                );
            }

            // Вертикальный вопрос
            if (question.direction === 'vertical' && cellIndex === startCol && rowIndex >= startRow && rowIndex < startRow + question.length) {
                const answerIndex = rowIndex - startRow;
                return (
                    <input
                        className="grid-cell answer-cell"
                        style={{ width: `${cellSize}px`, height: `${cellSize}px` }}
                        key={`${rowIndex}-${cellIndex}`}
                        value={answer[answerIndex] || ''}
                        maxLength={1}
                        draggable
                        onDragStart={(e) => handleDragStart(e, index)}
                        onDragOver={handleDragOver}
                        onDrop={(e) => handleDrop(e, rowIndex, cellIndex)}
                        onChange={(e) => onCellChange(rowIndex, cellIndex, e.target.value)}
                    />
                );
            }
        }

        // Пустая ячейка (неиспользуемая)
        return (
            <div
                className="grid-cell unused-cell"
                style={{ width: `${cellSize}px`, height: `${cellSize}px` }}
                key={`${rowIndex}-${cellIndex}`}
                onDragOver={handleDragOver}
                onDrop={(e) => handleDrop(e, rowIndex, cellIndex)}
            />
        );
    };

    return (
        <div
            className="grid"
            style={{ '--row': rows, '--column': columns, '--cell-size': `${cellSize}px` }}
        >
            {Array.from({ length: rows }).map((_, rowIndex) =>
                Array.from({ length: columns }).map((_, cellIndex) => renderCell(rowIndex, cellIndex))
            )}
        </div>
    );
};

export default Grid;
